.hero {
  padding: 0 80px;
  position: relative;
  height: auto;
  overflow: visible;
  background: var(--primary-900);
}

.hero::before {
  content: "";
  position: absolute;
  z-index: 0;
  background-blend-mode: color-burn;
  top: 150px;
  right: 25%;
  bottom: 0;
  width: 30%;
  height: 35%;
  background: url("../images/others/mesh-gradiant.svg");
  background-blend-mode: color-burn;
  background-repeat: no-repeat;
  background-size: cover;
  transform-origin: center;
  filter: blur(150px);
  animation: move-background 8s forwards infinite;
}
@keyframes move-background {
  0% {
    right: 20%;
    transform: rotate(0);
  }
  50% {
    right: 50%;
    transform: rotate(180deg);
  }
  100% {
    right: 20%;
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 635px) {
  .hero {
    padding: 0 20px;
    padding-bottom: 25px;
    margin: 0 auto;
  }
  .hero::before {
    filter: blur(90px);
  }
}
