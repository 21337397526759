.contact-us {
  padding: 100px 80px;
  row-gap: 80px;
  height: 829px;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.contact-us .contains {
  width: 100%;
}
.contact-us .container {
  position: relative;
}
.contact-us .container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: visible;
  transform: scale(1.004, 1.007);
  border-radius: 20px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  z-index: -2;
}
.contact-us .container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: var(--background);
  z-index: -1;
}
.contact-us .icon-digital-contact {
  left: auto;
  top: auto;
  pointer-events: none !important;
  user-select: none !important;
  bottom: 0;
  right: -10%;
  overflow: visible;
  animation: anim-icon 25s linear infinite;
  animation-direction: reverse;
}
@keyframes anim-icon {
  0% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0.05;
    transform: scale(100%);
  }
  8.5% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0.05;
    transform: scale(90%);
  }
  17% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0.05;
    transform: scale(100%);
  }
  25.5% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0.05;
    transform: scale(90%);
  }
  34% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0.05;
    transform: scale(100%);
  }
  42.5% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0;
    transform: scale(0);
  }
  46% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0;
    transform: scale(0);
  }
  51% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0.05;
    transform: scale(100%);
  }
  59.5% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0.05;
    transform: scale(90%);
  }
  68% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0.05;
    transform: scale(100%);
  }
  76.5% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0.05;
    transform: scale(90%);
  }
  85% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0.05;
    transform: scale(100%);
  }
  93.5% {
    left: -10%;
    top: 0;
    bottom: auto;
    right: auto;
    opacity: 0;
    transform: scale(0);
  }
  96% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    left: auto;
    top: auto;
    bottom: 0;
    right: -10%;
    opacity: 0.05;
    transform: scale(100%);
  }
}
.contact-us .contains .title > h3 {
  max-width: 650px;
  text-align: center;
}

.contact-us .contains .container {
  width: 100%;
  padding: 135px 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}
.contact-us .contains .container > div {
  gap: 16px;
}

.contact-us .contains > .container > div {
  max-width: 650px;
}

.contact-us .contains > .container > div p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;
}

.contact-us .contains > .container > div button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 10px;
  margin-top: 8px;
}

.contact-us .contains .line-contact .info,
.contact-us .contains .line-contact .contact {
  box-sizing: border-box;
  padding: 32px;
  height: 464px;
  border-radius: 10px;
  background-color: var(--primary-50);
}
.contact-us .contains .line-contact .info {
  row-gap: 40px;
}
.contact-us .contains .line-contact .contact {
  row-gap: 16px;
}
.contact-us .contains .line-contact .info > div {
  width: 100%;
}
.contact-us .contains .line-contact .info > .title,
.contact-us .contains .line-contact .contact .title {
  row-gap: 16px;
}
.contact-us .contains .line-contact .info > .title h4,
.contact-us .contains .line-contact .contact .title h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 27.65px;
  color: var(--neutre-gris-900);
}
.contact-us .contains .line-contact .info > .title p,
.contact-us .contains .line-contact .contact .title p {
  font-style: normal;
  font-weight: 450;
  color: var(--neutre-gris-500);
}
.contact-us .contains .line-contact .info > .info-sup {
  column-gap: 8px;
  row-gap: 8px;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "img title"
    ". info";
}
.contact-us .contains .line-contact .info > .info-sup img {
  grid-area: img;
}
.contact-us .contains .line-contact .info > .info-sup span {
  color: var(--primary-500);
  font-weight: 500;
  grid-area: title;
}
.contact-us .contains .line-contact .info > .info-sup span:last-child {
  color: var(--neutre-gris-500);
  font-weight: 450;
  grid-area: info;
}

.contact-us .contains .line-contact .contact .bottom {
  row-gap: 16px;
  max-width: 490px;
  height: 100%;
  align-items: flex-start;
}

.contact-us .contains .line-contact .contact .bottom > button {
  padding: 12px 20px;
  background-color: var(--primary-500);
  border: none;
  outline: none;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  color: var(--white);
}

.contact-us .contains .line-contact .contact .bottom > div:first-child {
  border-radius: 10px;
  border: 1px solid #172368;
  background: var(--white);
  width: 100%;
  padding: 12px;
}

.contact-us .contains .line-contact .contact .bottom > div:first-child > div {
  position: relative;
  height: 100%;
  width: 100%;
}
.contact-us
  .contains
  .line-contact
  .contact
  .bottom
  > div:first-child
  > div
  img {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.contact-us
  .contains
  .line-contact
  .contact
  .bottom
  > div:first-child
  > div
  input::placeholder {
  font-style: normal;
  font-weight: 450;
  color: var(--neutre-gris-500);
}
.contact-us
  .contains
  .line-contact
  .contact
  .bottom
  > div:first-child
  > div
  input {
  padding: 0;
  padding-left: 30px;
  outline: none;
  border: none;
  width: 100%;
  color: var(--neutre-gris-500);
  font-style: normal;
  font-weight: 450;
  height: 100%;
}

.contact-us .contains .line-contact .contact .bottom > textarea::placeholder {
  color: var(--neutre-gris-500);
  font-style: normal;
  font-weight: 450;
  font-size: 13.33px;
}

.contact-us .contains .line-contact .contact .bottom > textarea {
  flex: 1;
  width: 100%;
  padding: 6px 12px;
  outline: none;
  border: 1px solid #172368;
  border-radius: 10px;
  background: var(--white);
  color: var(--neutre-gris-500);
  font-style: normal;
  font-weight: 450;
}
@media screen and (max-width: 635px) {
  .contact-us {
    padding: 70px 20px;
    overflow-x: hidden;
    overflow-y: visible;
    height: auto;
  }
  .contact-us .icon-digital-contact {
    bottom: -90px;
    right: -190px;
    overflow: visible;
    animation: anim-icon 10s linear forwards infinite;
  }
  @keyframes anim-icon {
    from {
      left: calc(100% - 320px);
      bottom: -25%;
      transform: scale(50%);
      opacity: 0.05;
    }
    50% {
      left: calc(100% - 320px);
      bottom: -25%;
      transform: scale(45%);
      opacity: 0.05;
    }
    100% {
      left: calc(100% - 320px);
      bottom: -25%;
      transform: scale(50%);
      opacity: 0.05;
    }
  }
  .contact-us .contains .container {
    width: 100%;
    padding: 70px 5px;
  }
  .contact-us .contains .container > div {
    width: 260px;
  }
  .contact-us .contains > .container > div p {
    font-size: 12px;
    line-height: 15.18px;
  }
  .contact-us .contains .title > h3 {
    font-size: 20px;
    line-height: 25.3px;
  }
  .contact-us .contains > .container > div button {
    font-size: 12px;
  }
}
