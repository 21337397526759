.work .contains-card .wrapper .marquee .marquee-group .image-group .card-think {
  background-color: var(--background-card);
  height: 179px;
  border-radius: 10px;
  padding: 24px;
  row-gap: 20px;
  width: 413px;
  white-space: normal;
  position: relative;
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: visible;
  transform: scale(1.0055, 1.007);
  border-radius: 10px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  z-index: -2;
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: var(--background);
  z-index: -1;
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  .top-info {
  display: grid;
  gap: 16px;
  row-gap: 2px;
  align-items: center;
  grid-template-rows: auto auto;
  grid-template-columns: 50px auto;
  grid-template-areas: "img name" "img title";
}

.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  .top-info
  img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  grid-area: img;
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  .top-info
  h5,
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  .top-info
  span {
  font-style: normal;
  font-weight: 500;
  font-size: 13.33px;
  color: var(--primary-txt);
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  .top-info
  span {
  font-weight: 450;
  color: var(--neutre-gris-500);
  align-self: flex-start;
  flex: 1;
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  .top-info-flex {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-start;
}
.work
  .contains-card
  .wrapper
  .marquee
  .marquee-group
  .image-group
  .card-think
  > p {
  font-weight: 450;
  font-size: 13.33px;
  line-height: 20px;
  color: var(--primary-txt);
  align-self: flex-start;
  height: 100%;
  width: 100%;
  word-break: break-word;
  overflow: hidden;
}

@media screen and (max-width: 635px) {
  .work
    .contains-card
    .wrapper
    .marquee
    .marquee-group
    .image-group
    .card-think {
    background-color: var(--background-card);
    height: 180px;
    /* border: 1px solid #e5e7eb; */
    border-radius: 10px;
    padding: 24px;
    row-gap: 20px;
    white-space: normal;
  }
  .work
    .contains-card
    .wrapper
    .marquee
    .marquee-group
    .image-group
    .card-think
    > p {
    font-size: 12px;
  }
}

@media screen and (max-width: 635px) {
  .work .contains-card .card-think {
    background-color: var(--background-card);
    height: 150px;
    /* height: calc(100vw / 1.6944 - 10px / 1.6944); */
    /* border: 1px solid #e5e7eb; */
    border-radius: 10px;
    padding: 24px;
    row-gap: 20px;
    white-space: normal;
  }
  .work .contains-card .card-think .top-info {
    display: grid;
    gap: 16px;
    row-gap: 2px;
    align-items: center;
    grid-template-rows: auto auto;
    grid-template-columns: 50px auto;
    grid-template-areas: "img name" "img title";
  }
  .work
  .contains-card
  .card-think
  .top-info-flex {
  display: flex !important;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-start;
}
  .work .contains-card .card-think > p {
    font-size: 12px;
    line-height: 15.18px;
  }
  .work .contains-card .card-think > .top-info > h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .work .contains-card .card-think > .top-info > span {
    font-weight: 450;
    font-size: 12px;
    color: var(--neutre-gris-500);
    align-self: flex-start;
    flex: 1;
  }
  .work .contains-card .card-think .top-info img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    grid-area: img;
  }
  .work .contains-card .card-think > p {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.22;
    color: var(--primary-txt);
    align-self: flex-start;
    height: 100%;
    width: 100%;
    word-break: break-word;
    overflow: hidden;
  }
}
