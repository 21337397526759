.notif {
  position: fixed;
  right: 0;
  bottom: 25px;
  width: 350px;
  height: 90px;
  padding: 20px;
  border-radius: 20px;
  background: #111827;
  box-shadow: -33.1579px 27.8098px 26.7402px rgba(148, 148, 148, 0.07),
    -1.9745px 4.8353px 8.253px rgba(148, 148, 148, 0.12),
    -0.20883px 0.20883px 1.6961px rgba(148, 148, 148, 0.14);
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 23;
  animation: show-desap 5s forwards;
}

@keyframes show-desap {
  from {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(-10%);
  }
  90% {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(100%);
  }
}

.notif .contains {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.notif .contains > img {
  position: absolute;
  left: -154px;
  top: -214px;
  transform: scale(0.3);
  opacity: 0.15;
}

.notif .contains > span {
  color: var(--white);
  font-size: 22px;
  width: 100%;
  height: 100%;
  text-align: center;
}
@media screen and (max-width: 635px) {
    .notif {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 90px;
        padding: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transform: translateY(100%);
        background: #111827;
        animation: show-desap-2 5s 0.2s forwards !important;
      }
      .notif .contains {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @keyframes show-desap-2 {
        from {
          transform: translateY(100%);
        }
        10% {
          transform: translateY(0%);
        }
        90% {
          transform: translateY(0%);
        }
        to {
          transform: translateY(100%);
        }
      }
      .notif .contains > span {
        font-size: 18px;
      }
}

@media screen and (max-width: 280px) {
  .notif .contains > span {
    font-size: 16px;
  }
}