.projet {
  padding: 65px 80px;
  padding-bottom: 105px;
  row-gap: 80px;
  position: relative;
  justify-content: flex-start !important;
  overflow: visible;
}
.projet .title {
  max-width: 655px;
  row-gap: 16px;
}
.projet .title p {
  max-width: 629px;
}
.icon-digital-projet {
  pointer-events: none !important;
  user-select: none !important;
  left: 0;
  top: auto;
  bottom: -5%;
  right: auto;
  overflow: visible;
  animation: anim-icon-pr 25s forwards infinite;
}

.projet > .projet-contains {
  opacity: 0;
}
.visible > .projet-contains {
  opacity: 0;
  animation: intersection-show-projet-contains 1s 0.5s forwards;
}

@keyframes intersection-show-projet-contains {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim-icon-pr {
  0% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0.05;
    transform: scale(100%);
  }
  8.5% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0.05;
    transform: scale(90%);
  }
  17% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0.05;
    transform: scale(100%);
  }
  25.5% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0.05;
    transform: scale(90%);
  }
  34% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0.05;
    transform: scale(100%);
  }
  42.5% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0;
    transform: scale(0);
  }
  46% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0;
    transform: scale(0);
  }
  51% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0.05;
    transform: scale(100%);
  }
  59.5% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0.05;
    transform: scale(90%);
  }
  68% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0.05;
    transform: scale(100%);
  }
  76.5% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0.05;
    transform: scale(90%);
  }
  85% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0.05;
    transform: scale(100%);
  }
  93.5% {
    left: 0;
    top: auto;
    bottom: -5%;
    right: auto;
    opacity: 0;
    transform: scale(0);
  }
  96% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    left: auto;
    top: -35%;
    bottom: auto;
    right: 0%;
    opacity: 0.05;
    transform: scale(100%);
  }
}
.projet > .projet-contains {
  overflow: visible;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  user-select: none;
}

.projet > .projet-contains > div:nth-of-type(4),
.projet > .projet-contains > div:nth-of-type(5),
.projet > .projet-contains > div:nth-of-type(6) {
  user-select: none;
  mask-image: linear-gradient(
    to bottom,
    hsl(0 0% 0%/1) 10%,
    hsla(0, 0%, 0%, 0) 50%,
    hsl(0 0% 0%/0)
  );
}

.btn-see-work {
  align-self: baseline;
  position: relative;
  display: flex;
  align-items: center;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%,-200%);
  border-radius: 10px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  padding: 12px 20px;
  gap: 8px;
  transition: 0.3s;
}
.btn-see-work::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1.006, 1.01);
  border-radius: 10px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  z-index: -2;
}

.btn-see-work:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .projet > .projet-contains {
    overflow: visible;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    width: 100%;
    user-select: none;
  }
  .projet > .projet-contains > div {
    width: calc(50% - 10px) !important;
  }
  .projet > .projet-contains > div:nth-of-type(5),
  .projet > .projet-contains > div:nth-of-type(6) {
    display: none;
  }
  .projet > .projet-contains > div:nth-of-type(3),
  .projet > .projet-contains > div:nth-of-type(4) {
    user-select: none;
    mask-image: linear-gradient(
      to bottom,
      hsl(0 0% 0%/1) 100%,
      hsla(0, 0%, 0%, 0) 100%,
      hsl(0 0% 0%/0)
    );
  }
}

@media screen and (max-width: 635px) {
  .projet {
    padding: 60px 20px;
    padding-bottom: 60px;
    row-gap: 20px;
  }
  .projet > .projet-contains > div {
    width: 100% !important;
  }
  .projet .title {
    max-width: 629px;
    row-gap: 16px;
    margin-bottom: 52px;
  }
  .projet > .projet-contains > div:nth-of-type(4),
  .projet > .projet-contains > div:nth-of-type(5),
  .projet > .projet-contains > div:nth-of-type(6) {
    display: none;
  }
  .btn-see-work {
    bottom: 0px;
  }
  .icon-digital-projet {
    display: none;
  }
}
