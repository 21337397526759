.contact-form {
  position: fixed;
  height: 100dvh;
  width: 100%;
  padding: 20px 80px;
  opacity: 0;
  z-index: 0;
  row-gap: 40px;
  pointer-events: none;
}
.show-me-contact {
  opacity: 1 !important;
  z-index: 22;
  pointer-events: all !important;
}
.contact-form > img {
  transform: translateX(-10px);
}
.contact-form::before {
  content: "";
  background: rgba(2, 6, 32, 0.95);
  backdrop-filter: blur(4px);
  z-index: -1;
  left: 0;
  top: 0;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.contact-form .contains .title {
  width: 370px;
}
.contact-form .contains > button:nth-of-type(1) {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  padding-right: 8px;
  gap: 5px;
  transform: translate(40%, -50%);
  height: 42px;
  width: 85px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
}
.contact-form .contains > button:nth-of-type(1) > span {
  font-size: 16px !important;
  color: var(--white);
}
.contact-form .contains > button:nth-of-type(1) > .contains-btn {
  position: relative;
  width: 100%;
  height: 100%;
}
.contact-form .contains > button:nth-of-type(1) > .contains-btn div {
  border: 2px solid white;
  position: absolute;
  left: 3.5px;
  top: 45%;
  width: 80%;
  transform: rotate(45deg);
}
.contact-form .contains > button:nth-of-type(1) > .contains-btn div:last-child {
  transform: rotate(-45deg);
}
.contact-form .contains span {
  text-align: center;
  color: var(--neutre-gris-500);
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;
}
.contact-form h3 {
  max-width: 650px;
  font-size: 47px;
  color: var(--primary-txt);
  text-align: center;
}
.contact-form .contains {
  pointer-events: all;
  height: 100%;
  position: relative;
  max-height: 520px;
  padding: 25px 20px;
  width: auto;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 20px;
  row-gap: 15px;
}
.contact-form .contains .input-contact {
  row-gap: 10px;
  background: var(--primary-50);
  height: 45px;
  width: 95%;
  border-radius: 10px;
  padding: 15px;
}

.contact-form .contains .input-contact:nth-of-type(5) {
  height: 100%;
}

.contact-form .contains button {
  padding: 15px 20px;
  border-radius: 10px;
  border: none;
}
.contact-form .contains .input-contact input,
.contact-form .contains > .input-contact textarea {
  padding: 15px;
  padding-left: 15px;
  padding-right: 0;
  width: 100%;
  border: none;
  background: none;
  outline: none;
  color: var(--primary-500);
  font-style: normal;
  font-weight: 450;
}
.contact-form .contains > .input-contact textarea {
  padding-top: 0;
  height: 100%;
}
.contact-form .contains > .input-contact input::placeholder,
.contact-form .contains > .input-contact textarea::placeholder {
  color: var(--primary-500);
}

@media screen and (max-width: 635px) {
  .contact-form {
    padding: 20px 20px;
  }
  .contact-form .contains > button:nth-of-type(1) {
    position: absolute;
    right: 10%;
    top: 0;
    padding: 5px;
    padding-right: 8px;
    gap: 5px;
    transform: translate(50%, -50%);
    height: 42px;
    width: 85px;
    background: linear-gradient(
      150.37deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
  }
  .contact-form .contains {
    height: 100%;
    max-height: 450px;
    padding: 20px 20px;
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    row-gap: 10px;
  }
  .contact-form .contains .title {
    width: 100%;
  }
  .contact-form .contains span {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    color: var(--neutre-gris-500);
    font-size: 12px;
    line-height: 15.18px;
    font-weight: 450;
  }
  .contact-form h3 {
    font-size: 24px;
  }
}
