.skills > div > .do-skills {
  width: 100%;
}

.skills > div > .do-skills {
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.skills > div > .do-skills > div {
  position: relative;
  background-color: var(--background-card);
  border-radius: 10px;
  width: 413px;
  height: 228px;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  justify-content: flex-start;
  transition: 0.3s;
  overflow: visible;
}
.skills > div > .do-skills > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1.006,1.01);
  border-radius: 10px;
  background: linear-gradient(150.37deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  z-index: -2;
}
.skills > div > .do-skills > div::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: var(--background);
  z-index: -1;
}
.skills > div > .do-skills > div > .top {
  row-gap: 24px;
}

.skills > div > .do-skills > div > .top > .img-div {
  position: relative;
  z-index: 0;
}

.skills > div > .do-skills > div > .top > .img-div > div {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  left: -4px;
  z-index: -1;
  opacity: 0.5;
}

.skills > div > .do-skills > div > .bottom {
  width: 275px;
  row-gap: 8px;
}
.skills > div > .do-skills > div:nth-of-type(3) > .bottom {
  width: 290px;
  row-gap: 8px;
}
.skills > div > .do-skills > div > .bottom > h4 {
  color: var(--primary-txt);
  font-weight: 500;
  font-size: 23.04px;
  letter-spacing: 0;
}
.skills > div > .do-skills > div > .bottom > p {
  color: var(--neutre-gris-500);
  font-weight: 450;
  letter-spacing: 0;
  width: 308px;
}

.skills > div > .top > span {
  font-weight: 500;
  font-size: 13.33px;
  color: var(--primary-900);
  letter-spacing: 0;
}
@media screen and (max-width: 1050px) {
  .skills > div > .do-skills > div:nth-of-type(3) > .bottom {
    width: 100% !important;
  }
  .skills > div > .do-skills {
    align-items: stretch !important;
    gap: 16px;
  }
  .skills > div > .do-skills > div {
    height: auto;
    width: calc(50% - 8px);
  }
  .skills > div > .do-skills > div > .bottom > p {
    width: 100%;
  }
  .skills > div > .do-skills > div > .bottom {
    max-width: 275px;
    width: 100%;
    height: 100%;
    row-gap: 8px;
  }
}

@media screen and (max-width: 635px) {
  .skills > div > .do-skills > div > .bottom > p {
    max-width: 308px;
    width: auto;
  }

  .skills > div > .do-skills {
    height: auto;
  }
  .skills > div > .do-skills > div {
    max-width: 100%;
    width: 100%;
    padding: 16px;
    height: auto;
  }
  .skills > div > .do-skills > div > .bottom > h4 {
    font-size: 16px;
  }
  .skills > div > .do-skills > div > .top > .img-div > img {
    width: 24px;
    height: 24px;
  }
  .skills > div > .do-skills > div > .top > .img-div > div {
    width: 16px;
    height: 16px;
  }
  .skills > div > .do-skills > div > .bottom {
    width: 275px;
    max-width: 100%;
    row-gap: 8px;
  }
}
