.container-fixed-menu-t {
  display: none;
  position: fixed;
  z-index: 10;
  align-items: flex-start;
  background-color: rgb(2, 6, 32);
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: 0.15s;
}
.container-fixed-menu-actif {
  pointer-events: all !important;
  opacity: 1 !important;
}

.container-fixed-menu-no-active {
  opacity: 0 !important;
  pointer-events: none !important;
}

.container-fixed-menu-t > button {
  font-weight: 500;
  font-size: 20px;
  background: none;
  border: none;
  outline: none;
  color: var(--white);
  width: 100%;
  text-align: left;
  padding: 40px 0px 40px 80px;
  transform: translateX(-100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.container-fixed-menu-actif > button {
  animation: show-us 0.2s forwards ease-out;
}

.container-fixed-menu-actif > button:nth-of-type(2) {
  animation-delay: 0.15s;
}
.container-fixed-menu-actif > button:nth-of-type(3) {
  animation-delay: 0.3s;
}
.container-fixed-menu-actif > button:nth-of-type(4) {
  animation-delay: 0.45s;
}
.container-fixed-menu-actif > button:hover {
  padding: 45px 0px 45px 80px;
  background-color: var(--secondary-500) !important;
}

@keyframes show-us {
  from {
    transform: translateX(-100%);
    background-color: var(--secondary-500);
  }
  to {
    transform: translateX(0%);
    background-color: rgba(0, 0, 0, 0);
  }
}
header {
  width: 100%;
  flex: 1;
  height: 114px;
}

header > .contains {
  width: 100%;
  column-gap: 20px;
  justify-content: space-between !important;
  margin: 0 auto;
}

header > .contains nav {
  max-width: 510px;
  width: 100%;
}

header > .contains nav ul {
  width: 100%;
  justify-content: space-between !important;
}

header > .contains button {
  outline: none;
  border: none;
  background: none;
  font-weight: 500;
}

header > .contains > button {
  padding: 12px 20px;
  border-radius: 10px;
  background: var(--secondary-500);
  color: var(--white);
}

header > .contains > .menu-icon {
  display: none;
  padding: 0;
  box-sizing: border-box;
  background: none;
  justify-content: center;
  padding: 0 6px;
  border-radius: 3px;
  border: 0.5px solid var(--neutre-gris-500);
  width: 32px;
  height: 32px;
}
header > .contains > .menu-icon > .contains {
  position: relative;
  height: 12px;
  justify-content: space-between;
}
header > .contains > .menu-icon > .contains > div {
  width: 100%;
  border: 1px solid #ffff;
  border-radius: 10px;
  opacity: 1;
  transition: 0.15s;
  margin-top: 0;
}
header > .contains > .menu-icon > .contains > div:last-child {
  width: 9.17px;
}

header > .contains > .menu-icon > .show-active-cross > div:nth-of-type(1) {
  position: absolute;
  transform: rotate(45deg);
  top: 50%;
}
header > .contains > .menu-icon > .show-active-cross > div:nth-of-type(2) {
  position: absolute;
  transform: rotate(-45deg);
  top: 50%;
}
header > .contains > .menu-icon > .show-active-cross > div:last-child {
  transition: 0.2s;
  position: absolute;
  bottom: 0;
  transform: translate(-15px);
  opacity: 0;
}

header {
  padding: 0 80px;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.15s;
}

.show-me-header {
  background-color: rgba(2, 6, 32, 0.9);
  backdrop-filter: blur(4px);
  position: fixed;
  animation: show-header 0.5s forwards;
  box-shadow: 0px 0.5px 8px rgba(2, 6, 32, 0.807);
}
header > .contains > img {
  transition: 0.3s;
}
header > .contains > img:hover {
  opacity: 0.8;
  cursor: pointer;
}
@keyframes show-header {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
@media screen and (max-width: 1080px) {
  header > .contains nav {
    max-width: 410px;
    width: 100%;
  }
}
@media screen and (max-width: 1050px) {
  .no-background-visible {
    position: fixed !important;
    background-color: rgba(2, 6, 32, 1) !important;
    transform: translateY(0%) !important;
  }
  .container-fixed-menu-t {
    display: block;
  }

  header {
    height: 86px;
    width: 100%;
    flex: 1;
    padding: 0 80px;
    justify-content: space-between !important;
    max-width: 1280px;
    margin: 0 auto;
  }
  header > .contains > img {
    height: 28.74111557006836px;
    width: 149px;
  }
  header > .contains nav {
    display: none;
  }
  header > .contains > .menu-icon {
    display: block;
  }
  header > .contains button {
    display: none;
  }
}
@media screen and (max-width: 635px) {
  header {
    padding: 0 20px;
  }
  .container-fixed-menu-t > button {
    font-size: 18px;
    padding: 24px 0px 24px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .container-fixed-menu-t > button:hover {
    padding: 30px 0px 30px 20px;
    background-color: var(--secondary-500);
  }
}
