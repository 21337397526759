footer {
  background: var(--background);
  padding: 65px 80px;
  row-gap: 56px;
  position: relative;
  z-index: 1;
}

footer > div {
  gap: 129px;
  width: 100%;
}

footer .info > .logo-mobile {
  display: none;
}

footer .info .contains-info > .logo-mobile {
  justify-content: space-between;
  display: none;
}
footer > div > .options-nav {
  gap: 90px;
}

footer > div > .options-nav > div {
  row-gap: 24px;
}

footer > .info > .options-nav > div {
  row-gap: 24px;
}

footer > .info > .options-nav > div ul li {
  cursor: pointer;
}
footer > .info > .options-nav > div:last-child {
  row-gap: 0px;
  max-width: 270px;
}
footer > .info > .options-nav > div:last-child ul:last-child {
  margin-top: 8px;
}

footer > .info > .options-nav > div > ul {
  row-gap: 8px;
}

footer > .info > .options-nav > div > h3,
footer > .info > .options-nav > div > ul > li {
  color: var(--primary-txt);
}

footer > .info > .options-nav > div:nth-of-type(2) > h3 {
  width: 143px;
}
footer > .info > .options-nav > div > h3 {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 19.2px;
  letter-spacing: 0;
}

footer > .info > .options-nav > div > ul > li:hover {
  cursor: pointer;
}

.options-nav .nav .social {
  margin-top: 24px;
  gap: 24px;
}
footer .info .contains-info .ipnew {
  transition: 0.2s;
  padding: 24px;
  width: 413px;
  height: 215px;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--white);
}

footer .info .contains-info .ipnew > img {
  width: 197px;
  height: 39px;
}

footer .info .contains-info .ipnew span {
  width: 302px;
  color: var(--neutre-gris-500);
  font-weight: 450;
  font-weight: lighter;
  line-height: 24px;
  letter-spacing: 0em;
}
footer .info .contains-info .ipnew > div {
  justify-content: space-between;
  gap: 8px;
}

footer .info .contains-info .ipnew > div > div {
  position: relative;
  height: 100%;
  width: 269px;
  border-radius: 10px;
  border: 1px solid #abb5ed;
  padding: 14px 12px;
}

footer .info .contains-info .ipnew > div > button {
  padding: 12px 20px;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--primary-500);
  font-weight: 500;
  letter-spacing: 0em;
  border: none;
  outline: none;
}

footer .info .contains-info .ipnew > div > div > img,
footer .info .contains-info .ipnew > div > div > svg {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

footer .info .contains-info .ipnew > div > div > input {
  padding: 0;
  padding-left: 32px;
  background: none;
  outline: none;
  border: none;
  width: 100%;
  color: var(--primary-500);
  font-style: normal;
  font-weight: 450;
}

footer .info .contains-info .ipnew > div > div > input::placeholder {
  font-style: normal;
  font-weight: 450;
  color: var(--primary-500);
}

footer > .copyr {
  row-gap: 24px;
}

footer > .copyr > hr,
footer > hr {
  width: 100%;
  background-color: var(--neutre-gris-100);
}

footer > .copyr > span {
  text-align: center;
  width: 100%;
  font-weight: 450;
  color: var(--white);
}

.contact-block li {
  justify-content: flex-start !important;
  gap: 18px;
}
.contact-num {
  gap: 0 !important;
}
.contact-num span p {
  text-align: left;
}
.contact-num span p a{
  justify-content: flex-start;
  transition: 0.3s;
}
.contact-num span p a:hover{
  opacity: 0.7;
  cursor: pointer;
}
.contact-num:hover {
  opacity: 1 !important;
  cursor: auto !important;
}
@media screen and (max-width: 1355px) {
  footer > div:nth-of-type(1) {
    width: 100%;
    column-gap: 25px;
    justify-content: space-between !important;
  }
  footer > div > .options-nav {
    max-width: 670px;
    flex: 1;
    column-gap: 15px;
    justify-content: space-between !important;
  }
  footer .info .contains-info .ipnew {
    padding: 24px 16px;
  }
  footer .info .contains-info .ipnew span {
    width: 260px;
  }
  footer .info .contains-info .ipnew > div > div {
    background-color: var(--primary-50);
  }
}

@media screen and (max-width: 1110px) {
  footer .info .contains-info .ipnew {
    width: 345px;
  }
  footer > div:nth-of-type(1) {
    width: 100%;
    flex-wrap: wrap;
    row-gap: 48px;
    align-items: flex-start;
    justify-content: space-between !important;
  }
}

@media screen and (max-width: 1050px) {
  footer .info .contains-info .ipnew > img {
    display: none;
  }
  footer .info > .logo-mobile {
    display: block;
  }
  footer .info .contains-info .ipnew {
    height: auto;
    width: 350px;
    row-gap: 10px;
  }
  .options-nav .nav .social {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 635px) {
  .options-nav .nav .social {
    margin-bottom: 0px;
  }
  footer > .info > .options-nav > div:last-child ul:last-child {
    min-width: 250px;
  }
  footer .info > .logo-mobile {
    display: none;
  }
  footer > .info > .options-nav > div:last-child {
    row-gap: 24px;
  }
  .options-nav > .nav:last-child {
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 70px;
  }
  .options-nav .nav .social {
    margin-top: 0px;
    gap: 29px;
    flex: 1;
  }
  footer .info > .logo-mobile {
    display: block;
    width: 149px;
  }
  footer {
    padding: 70px 20px;
    row-gap: 32px;
  }
  footer .info .contains-info {
    order: 2;
    width: 100%;
  }
  footer .info .contains-info .ipnew {
    height: auto;
    width: 100%;
    row-gap: 10px;
  }
  footer .info .contains-info .ipnew span {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    max-width: 288px;
    width: 100%;
    color: var(--neutre-gris-700);
  }
  footer .info .contains-info .ipnew > div > div {
    min-width: 140px;
    background-color: var(--primary-50);
  }
  footer > .info {
    row-gap: 32px;
    flex-direction: column !important;
  }
  footer .info .contains-info .ipnew > div > div > input::placeholder,
  footer .info .contains-info .ipnew > div > div > input {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
  }
  footer .info .contains-info .ipnew > div > button {
    font-size: 14px;
    flex: 1;
    max-width: 130px;
  }
  footer > div > .options-nav {
    width: 100%;
    gap: 70px;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 32px;
    order: 1;
    justify-content: flex-start !important;
  }
  footer > .info > .options-nav > div > h3 {
    font-weight: 700;
    font-size: 16px;
  }
  footer > .info > .options-nav > div > ul > li {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
  }
  footer
    > .info
    > .options-nav
    > div:last-child
    > ul:last-child
    > li:last-child {
    max-width: 225px;
  }
  footer > .copyr > span {
    font-size: 14px;
    color: var(--neutre-gris-500);
  }
  footer > .info > .options-nav > div:last-child {
    max-width: 100%;
  }
}

@media screen and (max-width: 320px) {
  footer .info .contains-info .ipnew > div {
    align-items: stretch;
    flex-direction: column !important;
  }
  footer .info .contains-info .ipnew > div > button {
    font-size: 14px;
    flex: 1;
    max-width: 100%;
  }
  footer .info .contains-info .ipnew > div > div {
    min-width: 100%;
    width: 100%;
    background-color: var(--primary-50);
  }
}
