.projet-card {
  flex-shrink: 1;
  width: 31%;
  border-radius: 10px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.3) 3.93%,
    rgba(255, 255, 255, 0) 96.18%
  );
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  padding: 1px;
  position: relative;
}

.projet-card .finish-div {
  position: absolute;
  left: 50%;
  top: 30%;
  padding: 15px;
  border-radius: 10px;
  background: var(--primary);
  transition: 0.3s;
  opacity: 0.6;
  transform: translateX(-50%);
  /* transform: rotate(45deg); */
}
.finish-card .top,
.finish-card .bottom {
  opacity: 1;
  transition: 0.3s;
}
.finish-card:hover .top {
  backdrop-filter: blur(3px);
  opacity: 0.3;
}
.projet-card:hover .finish-div {
  opacity: 1;
  background: var(--secondary);
  padding: 18px;
}

.projet-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1.002, 1.004);
  border-radius: 10px;
  background: linear-gradient(
    150.37deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: -2;
}
.projet-card:nth-of-type(4)::before,
.projet-card:nth-of-type(4)::after {
  opacity: 0;
}
.projet-card:nth-of-type(5)::before,
.projet-card:nth-of-type(5)::after {
  opacity: 0;
}
.projet-card:nth-of-type(6)::before,
.projet-card:nth-of-type(6)::after {
  opacity: 0;
}
.projet-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: var(--background);
  z-index: -1;
}
.projet-card:hover {
  transition: 0.3s;
  background-color: black;
  cursor: pointer;
}
.projet-card > .bottom {
  flex: 1;
  min-height: 141px;
  padding: 0 24px;
}

.projet-card > .bottom {
  box-sizing: border-box;
  padding: 16px 24px;
  row-gap: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  background-color: var(--background);
}

.projet-card > .bottom > .title-btm {
  width: 100%;
}

.projet-card > .bottom > .title-btm span {
  font-style: normal;
  font-weight: 450;
  font-size: 11.104px;
  line-height: 16px;
  color: var(--neutre-gris-500);
}
.projet-card > .bottom > .title-btm h3 {
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 700;
  font-size: 23.04px;
  color: var(--primary-txt);
}

.projet-card > .bottom > p {
  font-style: normal;
  font-weight: 450;
  max-height: 100%;
  overflow-y: auto;
  font-size: 13.33px;
  line-height: 20px;
  color: var(--neutre-gris-100);
}
.projet-card > .bottom > p::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
.projet-card > .top {
  min-height: 55%;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.projet-card > .top > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media screen and (max-width: 1050px) {
  .projet-card:nth-of-type(3)::before,
  .projet-card:nth-of-type(3)::after {
    opacity: 0;
  }
}
@media screen and (max-width: 730px) {
  .projet-card {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 635px) {
  .projet-card {
    width: 100%;
    height: 294px;
  }
  .projet-card > .bottom {
    box-sizing: border-box;
    padding: 16px;
    height: 109px;
    row-gap: 0;
    justify-content: space-evenly !important;
  }
  .projet-card > .top {
    max-height: 65%;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
    overflow: hidden;
  }

  .projet-card > .bottom > .title-btm h3 {
    font-size: 16px;
  }
  .projet-card > .bottom > .title-btm span {
    font-size: 8px;
    line-height: 12px;
  }
  .projet-card > .bottom > p {
    font-size: 12px;
    line-height: 15.18px;
  }
  .projet-card > .bottom {
    flex: 1;
    min-height: 50px;
  }
}
