@font-face {
  font-family: "Circular Std";
  src: url("fonts/Circular-Std-Book.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

:root {
  --primary: #172368;
  --background: #020620;
  --primary-100: #d5daf6;
  --primary-500: #172368;
  --primary-50: #eaedfa;
  --primary-200: #abb5ed;
  --primary-900: #020620;
  --warning-500: #f59f0b;
  --succes-300: #7ce9a4;
  --secondary: #d43555;
  --neutre-gris: #6b7280;
  --background-2: #f9fafb;
  --neutre-gris-100: #e5e7eb;
  --neutre-gris-900: #111827;
  --neutre-gris-500: #6b7280;
  --neutre-gris-700: #374151;
  --secondary-500: #d43555;
  --background-card: rgba(255, 255, 255, 0.1);
  --primary-txt: #ffffff;
  --normal-shadow: -33.1579px 27.8098px 26.7402px rgba(148, 148, 148, 0.07),
    -14.9745px 12.8353px 19.253px rgba(148, 148, 148, 0.12),
    -3.20883px 3.20883px 10.6961px rgba(148, 148, 148, 0.14);
  --primary-txt-dark: #ffffff;
  --white: #ffffff;
  --hero-background: #f9fafb;
}

html {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

body {
  margin: 0;
  margin-left: auto;
  width: 100%;
  background-color: var(--primary-900);
  overflow: hidden;
  overflow-y: visible;
}

ul {
  list-style: none;
}

*,
span,
header,
span,
button,
main,
footer {
  font-family: "Circular Std", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: var(--primary-txt);
}
a {
  text-decoration: none;
}

header,
main,
footer {
  overflow-x: hidden;
  width: 100%;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}

.colm {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.center {
  align-items: center;
  justify-content: center;
}

.transition {
  transition: 0.3s ease-out;
}

button,
li {
  transition: 0.3s ease-out;
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
}
button {
  background: var(--secondary-500);
}

li:hover {
  opacity: 0.8;
  cursor: pointer;
}

.max-width {
  max-width: 1280px;
  margin: 0 auto;
}

.max-width-msg {
  max-width: 1440px;
  margin: 0 auto;
}

.letter-spacing {
  letter-spacing: -0.02em;
}

main > section .title {
  /* padding: 0 20px; */
  justify-content: flex-start;
  letter-spacing: 0;
}

main > section .title p {
  font-size: 19.2px;
  line-height: 28px;
  font-weight: 450;
  letter-spacing: 0;
  text-align: center;
  color: var(--neutre-gris-500);
}

main > section .title h3 {
  text-align: center;
  font-weight: 700;
  font-size: 47.78px;
  color: var(--primary-txt);
}

.icon-digital {
  position: absolute;
  pointer-events: none !important;
  user-select: none !important;
  background-position: center;
  background-size: cover;
  opacity: 0.05;
  width: 526px;
  height: 481px;
  transform: scale(100%);
}

button:disabled {
  background-color: #111827 !important;
}
.loader:hover {
  cursor: progress !important;
}
button:disabled:hover {
  cursor: not-allowed;
}

section > .title {
  opacity: 0;
}

.visible > .title {
  opacity: 0;
  transform: translateY(50%);
  animation: intersection-show-title 0.5s ease-out forwards;
}

@keyframes intersection-show-title {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 635px) {
  main > section .title {
    width: 255px;
  }
  main > section .title p {
    font-size: 12px;
    line-height: 15px;
  }

  main > section .title h3 {
    line-height: 28px;
    font-size: 22px;
    text-align: center;
  }

  *,
  span,
  header,
  span,
  button,
  main,
  footer {
    font-size: 12px;
  }
}
