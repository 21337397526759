.skills {
  position: relative;
  padding: 80px;
  justify-content: flex-start;
  row-gap: 64px;
  overflow: visible;
}

.skills .title {
  max-width: 631px;
  justify-content: flex-start;
  row-gap: 16px;
}

.icon-digital-skills {
  pointer-events: none !important;
  user-select: none !important;
  display: none;
}

.skills div:last-child {
  row-gap: 20px;
}

.skills div:last-child > div:last-child {
  justify-content: center;
}

.skills > div:last-child {
  opacity: 0;
  transform: translateY(20%);
}

.visible > .skills-projet {
  animation: intersection-show-skills-card 1s 0.5s forwards;
}

@keyframes intersection-show-skills-card {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.visible > div > .do-skills {
  opacity: 1;
}

.skills > div > .do-skills:nth-of-type(2) > div:nth-of-type(1) {
  display: none;
}
@media screen and (max-width: 1050px) {
  .skills {
    padding-top: 0;
  }
  .skills div:last-child {
    row-gap: 16px;
  }
  .skills > div > .do-skills:nth-of-type(1) > div:nth-of-type(3) {
    display: none;
  }
  .skills > div > .do-skills:nth-of-type(2) > div:nth-of-type(1) {
    display: flex !important;
  }
  .skills > div > .do-skills {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 635px) {
  .skills {
    position: relative;
    margin: 0 auto;
    padding: 60px 20px;
    justify-content: flex-start;
    row-gap: 72px;
    height: 100%;
    width: 100%;
  }
  .skills .title {
    width: 271px;
  }
  .icon-digital-skills {
    display: block;
    position: absolute;
    bottom: 0;
    right: -70%;
    animation: anim-icon-skills 10s linear forwards infinite;
  }
  @keyframes anim-icon-skills {
    from {
      transform: scale(100%);
    }
    50% {
      transform: scale(95%);
    }
    100% {
      transform: scale(100%);
    }
  }
  .skills div:last-child {
    width: 100%;
  }
}
