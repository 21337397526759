.work {
  padding: 80px 0;
  padding-top: 0;
  justify-content: flex-start !important;
  position: relative;
  row-gap: 80px;
  overflow: visible;
}

.work .title {
  max-width: 655px;
  row-gap: 16px;
}
.work .title p {
  max-width: 541px;
}

.work .contains-card {
  position: relative;
  width: 100%;
  opacity: 0;
  transform: translateY(20%);
}

.work .contains-card .wrapper {
  width: 100%;
  height: fit-content;
  row-gap: 16px;
}

.visible > .contains-card {
  animation: intersection-show-thinks-section 1s 0.5s forwards;
}

@keyframes intersection-show-thinks-section {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.work .contains-card .wrapper .marquee {
  width: 100%;
  overflow: hidden;
  user-select: none;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0%/1) 10%,
    hsl(0 0% 0%/1) 90%,
    hsl(0 0% 0%/0)
  );
}
@keyframes animScrool {
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.work .contains-card .wrapper .marquee .marquee-group {
  flex-shrink: 0;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: auto;
  animation: animScrool 40s linear infinite;
  height: auto;
  gap: 20px;
  padding: 1.5px 0;
  box-sizing: border-box;
}

.work .contains-card .wrapper .marquee .marquee-group .image-group {
  place-items: center;
  height: 100%;
  display: inline-flex;
  width: 413px;
  height: 194px;
  flex-shrink: 0;
  /* padding: calc(clamp(10rem, 1rem +30vmin, 30rem) / 10); */
}
.work .contains-card .wrapper .marquee .marquee-group .image-group:last-child {
  margin-right: 20px;
}

.r-anim > div {
  animation-direction: reverse !important;
}

@media screen and (max-width: 635px) {
  .work {
    margin: 40px 0;
  }
  .work .contains-card {
    padding: 0 20px;
    width: 100%;
    height: 173px;
  }
  .message-swip {
    overflow: visible !important;
  }
  .message-swip .swiper-pagination {
    bottom: -40px !important;
  }
  .message-swip .swiper-pagination > span {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .message-swip .swiper-pagination > .swiper-pagination-bullet-active {
    background-color: var(--secondary-500);
  }
}
