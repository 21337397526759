.hero .inside {
  height: 530px;
  width: 100%;
  max-width: 1280px;
  margin: 80px auto;
  gap: 50px;
  justify-content: space-between !important;
  overflow: visible;
}

.hero .inside .slogan {
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 600px;
}

.visible > .slogan {
  animation: intersection-show-slogan 0.5s ease-out forwards;
}

@keyframes intersection-show-slogan {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hero .inside .slogan img {
  position: absolute;
  top: -5px;
  right: -30px;
}
.hero .inside .slogan p:nth-of-type(1),
.hero .inside .slogan span {
  font-size: 57.328px;
  font-weight: bolder;
  font-weight: 900;
  letter-spacing: -0.02em;
  color: var(--primary-txt);
}
.hero .inside .slogan p > span {
  position: relative;
  color: var(--secondary);
}

.slogan .second-slo p {
  width: 500px;
  margin-top: 16px;
  line-height: 28px;
  letter-spacing: 0 !important;
  font-weight: lighter !important;
  font-size: 19.2px !important;
  color: var(--neutre-gris-500) !important;
}

.slogan > button {
  margin-top: 30px;
  padding: 12px 20px;
  outline: none;
  border: none;
  background: var(--secondary-500);
  border-radius: 10px;
  color: var(--white);
}

.hide-typer .Typewriter > span:last-child {
  opacity: 0;
  animation: fade 0.5s;
}
@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.hero .inside .slogan img {
  position: absolute;
  top: -11px;
  right: -30px;
  z-index: 1;
  width: 43px;
  aspect-ratio: 1/1;
  transform: scale(0.8);
  background-size: cover;
  background-repeat: no-repeat;
  animation: showMeHighlight 8s infinite;
}

@keyframes showMeHighlight {
  from {
    transform: scale(0);
  }
  5% {
    transform: scale(0.8);
  }
  9% {
    transform: scale(0.45);
  }
  13% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.8);
  }
  95% {
    transform: scale(0.8);
  }
  to {
    transform: scale(0);
  }
}

.illustration {
  flex: 1;
  height: 100%;
  position: relative;
}

.slogan-title {
  /* position: absolute; */
  left: 0;
  top: 0;
  text-align: left;
  align-items: flex-start !important;
  width: auto;
}
.remplacement-slogan-title {
  position: relative;
  height: 134px;
  width: 100%;
  margin-bottom: 0;
  animation: go-height 0.5s 7.5s forwards;
}
@keyframes go-height {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: 70px;
  }
}

.visible > .illustration {
  opacity: 0;
  animation: intersection-show-illustation 0.5s 0.5s ease-out forwards;
}

@keyframes intersection-show-illustation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.visible > .illustration > .btn-comol {
  opacity: 0;
  transform: translateX(10%);
  animation: intersection-show-illustation-btn-comol 0.5s 1s ease-out forwards;
}

@keyframes intersection-show-illustation-btn-comol {
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.illustration > div {
  position: absolute;
  transition: 0.3s;
  border-radius: 25.67px;
  box-shadow: 0px 154.024px 62.0374px rgba(148, 148, 148, 0.02),
    0px 86.6384px 52.4109px rgba(148, 148, 148, 0.07),
    0px 38.5059px 38.5059px rgba(148, 148, 148, 0.12),
    0px 9.62649px 21.3922px rgba(148, 148, 148, 0.14),
    0px 0px 0px rgba(148, 148, 148, 0.14);
}

.illustration .btn-comol {
  box-shadow: none;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
}
.illustration .btn-comol .contains button {
  position: absolute;
  padding: 8px 26px;
  border: none;
  user-select: none;
  pointer-events: none;
  border-radius: 112px;
}
.illustration .btn-comol img {
  position: absolute;
  width: 21px;
  aspect-ratio: 1/1;
  right: 0;
  top: 0;
}
.illustration .btn-comol .contains {
  position: relative;
  width: 100%;
  height: 100%;
}
.illustration .btn-comol .contains button:nth-of-type(1) {
  left: 50px;
  top: 44px;
  /* animation: animbt1 2s linear forwards infinite; */
}
.illustration .btn-comol .contains img:nth-of-type(1) {
  left: 160px;
  top: 70px;
  /* animation: animMr 2s linear forwards infinite; */
}
.illustration .btn-comol .contains button:nth-of-type(2) {
  left: 337px;
  top: 485px;
  background: var(--primary-500);
  /* animation: animbt2 2s  reverse forwards infinite; */
}
.illustration .btn-comol .contains img:nth-of-type(2) {
  left: 526px;
  top: 466px;
  transform: rotate(70deg);
  /* animation: animMb 2s reverse forwards infinite; */
}
@keyframes animbt1 {
  from {
    top: 44px;
  }
  50% {
    top: 34px;
  }
  to {
    top: 44px;
  }
}
@keyframes animMr {
  from {
    top: 70px;
  }
  50% {
    top: 60px;
  }
  to {
    top: 70px;
  }
}
@keyframes animbt2 {
  from {
    top: 485px;
  }
  50% {
    top: 465px;
  }
  to {
    top: 485px;
  }
}
@keyframes animMb {
  from {
    top: 473px;
  }
  50% {
    top: 453px;
  }
  to {
    top: 473px;
  }
}

.illustration > div > div {
  position: relative;
}

.illustration .tel-contain {
  bottom: 90.56px;
  overflow: hidden;
  right: 0;
  box-shadow: 0px 154.024px 62.0374px rgba(148, 148, 148, 0.02),
    0px 86.6384px 52.4109px rgba(148, 148, 148, 0),
    0px 38.5059px 38.5059px rgba(148, 148, 148, 0.031),
    0px 9.62649px 21.3922px rgba(148, 148, 148, 0),
    0px 0px 0px rgba(148, 148, 148, 0.14);
}
.contains-panel-swipe {
  position: absolute;
  top: 20%;
  right: 8%;
  width: 88%;
  height: 93px;
  overflow: hidden;
}
.illustration .tel-contain .tel .pouce {
  position: absolute;
  top: 28%;
  opacity: 0;
  right: 10%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--secondary);
  animation: swipe 6s infinite;
  z-index: 3;
}
.illustration .tel-contain .tel .panel-swipe {
  position: absolute;
  /* top: 20%; */
  right: -283%;
  width: 700px;
  height: 93px;
  overflow: hidden;
  gap: 6px;
  z-index: 2;
  flex-wrap: nowrap;
  transform-origin: right;
  text-align: right;
  opacity: 1;
  animation: slidetoleft 6s infinite;
}
@keyframes slidetoleft {
  from {
    right: -283%;
    opacity: 1;
  }
  0.5% {
    opacity: 1;
  }
  2% {
    right: -283%;
  }
  2.5% {
    opacity: 0.7;
  }
  3% {
    opacity: 0.7;
    right: -283%;
  }
  9% {
    opacity: 0.7;
  }
  10% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  30% {
    right: -20%;
  }
  95% {
    opacity: 1;
  }
  to {
    right: -20%;
  }
}
.illustration .tel-contain .tel .panel-swipe div {
  height: 100%;
  width: 64px;
  border-radius: 10px;
  background-color: #dcdee5;
  display: inline-block;
  transform-origin: right;
  margin: 0 3px;
}

.tel-contain .tel .panel-swipe div:nth-child(1) {
  background-color: var(--neutre-gris);
}
.tel-contain .tel .panel-swipe div:nth-child(3) {
  background-color: var(--neutre-gris);
}
.tel-contain .tel .panel-swipe div:nth-child(5) {
  background-color: var(--neutre-gris);
}
.tel-contain .tel .panel-swipe div:nth-child(7) {
  background-color: var(--neutre-gris);
}
.tel-contain .tel .panel-swipe div:nth-child(9) {
  background-color: var(--neutre-gris);
}

@keyframes swipe {
  from {
    opacity: 0;
    right: 10%;
  }
  2% {
    opacity: 1;
    right: 10%;
  }
  9% {
    opacity: 1;
  }
  10% {
    opacity: 1;
    right: 75%;
  }
  15% {
    opacity: 0;
  }
  to {
    opacity: 0;
    right: 75%;
  }
}

.tab-contain {
  top: 9px;
  right: 130px;
  z-index: 4;
}

.tab-contain .tab .contains-top-anim {
  top: 84.5px;
  right: 33.16px;
  width: 117.66px;
  height: 87.71px;
  position: absolute;
}

.tab-contain .tab .contains-top-anim .anim-contain {
  position: relative;
  width: 100%;
  height: 87.71px;
}

.tab-contain .tab .contains-top-anim .anim-contain > div:first-child {
  position: absolute;
  width: 57.76px;
  height: 62.04px;
  top: 0;
  left: 0;
  background-color: #2388ff;
  border-radius: 9.63px;
  animation: blueMove 6s infinite;
}
@keyframes blueMove {
  from {
    top: -20%;
    left: 0;
  }
  20% {
    top: -20%;
    left: 0;
  }
  25% {
    top: -20%;
    left: 0;
  }
  40% {
    left: 55%;
    top: -20%;
  }
  45% {
    left: 55%;
    top: -20%;
  }
  60% {
    left: 55%;
    top: 50%;
  }
  65% {
    left: 55%;
    top: 50%;
  }
  80% {
    left: 0;
    top: 50%;
  }
  85% {
    left: 0;
    top: 50%;
  }
  to {
    top: -20%;
    left: 0;
  }
}
.tab-contain .tab .contains-top-anim .anim-contain > div:last-child {
  position: absolute;
  width: 54.55px;
  height: 51.34px;
  bottom: 5.35px;
  right: 0;
  background-color: #ffd707;
  border-radius: 9.63px;
  animation: yellowMove 6s infinite;
}

@keyframes yellowMove {
  from {
    right: 0;
    bottom: -15%;
  }
  20% {
    right: 0;
    bottom: -15%;
  }
  25% {
    right: 0;
    bottom: -15%;
  }
  40% {
    right: 55%;
    bottom: -15%;
  }
  45% {
    right: 55%;
    bottom: -15%;
  }
  60% {
    right: 55%;
    bottom: 55%;
  }
  65% {
    right: 55%;
    bottom: 55%;
  }
  80% {
    right: 0;
    bottom: 55%;
  }
  85% {
    right: 0;
    bottom: 55%;
  }
  to {
    right: 0;
    bottom: -15%;
  }
}

.tab-contain .tab .bar-filled {
  position: absolute;
  bottom: 121.2px;
  left: 47.06px;
  background-color: #2388ff;
  border-radius: 9.62649px;
  width: 56.69px;
  height: 7.49px;
  overflow: hidden;
  animation: widthanim 3s infinite;
}

.tab-contain .tab .second-bar-filled {
  position: absolute;
  bottom: 58.08px;
  left: 47.06px;
  background-image: linear-gradient(to right, var(--neutre-gris), #dcdee5);
  background-size: 400% 400%;
  border-radius: 9.62649px;
  width: 56.69px;
  height: 7.49px;
  overflow: hidden;
  transition: 1s;
  animation: gradiant 3s infinite;
}

@keyframes widthanim {
  from {
    width: 56.69px;
  }
  60% {
    width: 75px;
  }
  80% {
    width: 30px;
  }
}
@keyframes gradiant {
  from {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.illustration .pc-contain {
  position: absolute;
  bottom: 90.56px;
  right: 336.5px;
}

.illustration .pc-contain .pc > div:first-child,
.illustration .pc-contain .pc > div:nth-of-type(2) {
  position: absolute;
  width: 89.85px;
  height: 12.84px;
  background: #2388ff;
  border-radius: 64.1766px;
  top: 26.74px;
  left: 95px;
  animation: appearBar 2.1s 0.5s infinite ease;
}
.illustration .pc-contain .pc > div:nth-of-type(2) {
  background: #ffd707;
  top: 141.19px;
  animation: appearBar 2.5s 1.5s infinite ease;
}
.illustration .pc-contain .pc > div:nth-of-type(3) {
  position: absolute;
  width: 54.55px;
  height: 12.84px;
  background: #f72f4b;
  border-radius: 64.1766px;
  top: 256.71px;
  left: 216.71px;
  animation: appearBar 2.2s 1s infinite ease;
}
@keyframes appearBar {
  from {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
@media screen and (max-width: 1420px) {
  .illustration .pc-contain {
    bottom: 30.56px;
    right: 200.5px;
  }
  .illustration .tab-contain {
    transform: scale(80%);
  }
  .illustration .tel-contain {
    bottom: 90.56px;
    overflow: hidden;
    box-shadow: 0px 154.024px 62.0374px rgba(148, 148, 148, 0.02),
      0px 86.6384px 52.4109px rgba(148, 148, 148, 0),
      0px 38.5059px 38.5059px rgba(148, 148, 148, 0.031),
      0px 9.62649px 21.3922px rgba(148, 148, 148, 0),
      0px 0px 0px rgba(148, 148, 148, 0.14);
  }
  .illustration .btn-comol .contains button:nth-of-type(1) {
    right: 187px;
    left: auto;
    top: -25px;
  }
  .illustration .btn-comol .contains img:nth-of-type(1) {
    right: 291px;
    left: auto;
    top: 12px;
    transform: rotate(76deg);
  }
  .illustration .btn-comol .contains button:nth-of-type(2) {
    right: 87px;
    left: auto;
    top: 547px;
  }
  .illustration .btn-comol .contains img:nth-of-type(2) {
    right: 75px;
    left: auto;
    top: 522px;
    transform: rotate(64deg);
  }
}

@media screen and (max-width: 1185px) {
  .illustration {
    transform: scale(80%) !important;
  }
}
@media screen and (max-width: 1050px) {
  .hero .inside {
    height: auto;
    flex-direction: column !important;
    /* margin-top: 126px; */
    gap: 0px;
    margin-bottom: 150px;
    justify-content: space-between !important;
  }

  .hero .inside .slogan p {
    text-align: center;
  }

  .hero .inside .slogan img {
    position: absolute;
    top: -5px;
    right: 5px;
    width: 17.15px;
    height: 19px;
  }

  .illustration .tel-contain {
    bottom: -131px;
    overflow: hidden;
    right: 0;
    top: auto;
    left: auto;
  }

  .illustration .tab-contain {
    top: 0;
    bottom: auto;
    left: auto;
    right: 130px;
  }
  .illustration .pc-contain {
    right: auto;
    top: auto;
    bottom: -131px;
    z-index: 3;
  }

  .illustration .btn-comol {
    display: block;
  }
  .illustration .btn-comol .contains button:nth-of-type(1) {
    left: 50px;
    top: 44px;
    right: auto;
    bottom: auto;
    /* animation: animbt1 2s linear forwards infinite; */
  }
  .illustration .btn-comol .contains img:nth-of-type(1) {
    left: 160px;
    top: 70px;
    right: auto;
    bottom: auto;
    transform: rotate(0);
    /* animation: animMr 2s linear forwards infinite; */
  }
  .illustration .btn-comol .contains button:nth-of-type(2) {
    left: 337px;
    top: 485px;
    right: auto;
    bottom: auto;
  }
  .illustration .btn-comol .contains img:nth-of-type(2) {
    left: 526px;
    top: 466px;
    right: auto;
    bottom: auto;
    transform: rotate(70deg);
    /* animation: animMb 2s reverse forwards infinite; */
  }
  .hero .inside .slogan img {
    position: absolute;
    top: -5px;
    right: -12px;
  }
  .hero .inside .slogan {
    align-items: center;
    justify-content: flex-start;
  }
  .hero .inside .illustration {
    margin: 80px 0;

    flex: none;
    display: block;
    width: 640px;
    height: 300px;
    transform: scale(100%) !important;
  }
}

@media screen and (max-width: 800px) {
  .hero .inside .illustration {
    transform: scale(80%) !important;
  }
}
@media screen and (max-width: 675px) {
  .hero .inside .illustration {
    transform: scale(65%) !important;
  }
}
@media screen and (max-width: 635px) {
  .hero .inside {
    margin-bottom: 0;
  }
  .remplacement-slogan-title {
    margin-bottom: 0 !important;
    height: 87px !important;
    animation: go-heightt 0.5s 7.5s forwards !important;
  }
  @keyframes go-heightt {
    from {
      margin-bottom: 0;
    }
    to {
      margin-bottom: 15px;
    }
  }
  .hero .inside .illustration {
    margin: 0;
    transform: scale(50%) !important;
  }
  .illustration .btn-comol .contains button {
    font-size: 16px;
  }

  .hero .inside .slogan {
    width: 271px;
  }

  .hero .inside .slogan p:nth-of-type(1),
  .hero .inside .slogan span {
    width: 100%;
    font-size: 24px;
  }

  .slogan .second-slo p {
    width: 100%;
    line-height: 15.18px !important;
    font-size: 12px !important;
  }

  .slogan > button {
    font-size: 12px !important;
    margin-top: 34px;
    padding: 10px 16px;
  }
}
@media screen and (max-width: 340px) {
  .illustration .tel-contain {
    bottom: -131px;
    overflow: hidden;
    right: 40px;
    top: auto;
    left: auto;
  }
  .hero .inside .illustration {
    transform: scale(45%) !important;
  }
  .illustration .pc-contain {
    right: auto;
    top: auto;
    left: 60px;
    bottom: -131px;
  }
}
